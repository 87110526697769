.authpage {
    text-align: center;
    background-color: rgba(30, 30, 30, .6);
    background-image: url('./../../assets/bg1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &__content {
        z-index: 10;
    }

    &__shadow {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(30, 30, 30, .8);

    }

    &__block {
        color:#ddd;
        margin-bottom:3rem;
    }

    &__footer {
        position:absolute;
        bottom:2rem;
        color:#ddd;
        z-index:10;
    }

    h1 {
        font-size: 6rem;
        color: #ddd;
        text-shadow: 2px 2px #8b8b8b;
        margin-bottom:1rem;
    }
}


