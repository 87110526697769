@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');

body,
html {
    padding: 0 !important;
    margin: 0 !important;
    min-height: auto !important;
    min-width: auto !important;
    height: auto !important;
}

.print.page {
    width: 21cm;
    height: 29.7cm;
    font-size: 10.5pt;

    display: flex;
    flex-direction: column;

    header {
        flex-basis: 3.7cm;
        position: relative;

        hr {
            border-width: 2px;
            border-color: black;
            opacity: 1;
            margin-top: 1.6cm;
        }

        .print__logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-2cm) translateY(-1cm);
            margin: 0 auto;
            //height: 1.75cm;
            //background-color: green;
            background-color: white;


            img {
                width: 4cm;
                padding-left: .5cm;
                padding-right: .5cm;
            }
        }

    }

    footer {
        flex-basis: 6cm;
        background-color: #ddd;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        opacity: 0.75;
    }

    main {
        flex: 1;
        padding-left: 2cm;
        padding-right: 2cm;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .print {


        &__title {
            font-size: 22pt;
            margin-bottom: 1cm;

            &_1 {
                font-weight: bold;
            }

            &_2 {
                font-size: 20pt;
            }
            &_warning {
                font-size: 10.5pt;
                padding:.85pt 4pt;
                border-radius: 2pt;
                background-color: #f3e59f;
                color:#5b4f13;
                display:inline-block;

                span {
                    display:inline-block;
                    margin-top:-1pt;
                    vertical-align: top;
                }
            }
        }

        &__details {
            font-size: 14pt;
            margin-bottom: 1cm;

            display: flex;
            flex-direction: column;
            gap: 0.1cm;

            >div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 0.2cm;
            }

            .rating {
                font-size: 0.8rem;
            }
        }

        &__description {
            display: flex;
            flex-direction: row;
            gap: 0.75cm;
        }

        &__desc__text {
            flex: 1;

            .qrCode {
                margin-bottom:5px;
            }

            .promoBadge {
                background-color:#eee;
                padding:2px 6px;
                border-radius: 7px;
                font-size: .9em;
                margin-left:5px;
                font-weight: bold;
            }
        }
    }
}

.printWeek {
    font-family: 'Barlow', sans-serif;

    width: 42cm;
    height: 29.5cm;
    font-size: 10pt;

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    >* {
        box-sizing: border-box;
    }

    >div {
        padding: 0.7cm;

        &:nth-child(-n+4) {
            border-bottom: 1px solid black;
        }
    }

    &__header {
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 80%;
        opacity: 1;

        &__date {
            margin-top: 0.4cm;
        }
    }



    &__header__title {
        margin-top: 4cm;
        font-size: 18pt;
        text-transform: uppercase;
        line-height: 1.6rem;

        &__title--0 {
            font-weight: bold;
            font-size: 1.1em;
        }


    }

    &__day {
        padding-left: 0px !important;
        padding-right: 0px !important;

        &:nth-child(5) {
            >div {
                border-left: none;
            }
        }

        &__wrapper {
            padding-left: 0.7cm;
            padding-right: 0.7cm;
            border-left: solid 1px black;
            height: 100%;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0.3cm;

            &__day {
                font-size: 11pt;
                font-weight: 500;
                line-height: 1.1rem;

                &__day--0 {
                    font-weight: bold;
                }
            }

            &__date {
                font-size: 9pt;
            }
        }

        &__activities {
            display: flex;
            flex-direction: column;
            gap: 0cm;

            &__activity {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: start;
                gap: 0.3cm;
                line-height: 1rem;
                margin-bottom:.2cm;

                &__name {
                    flex: 1;
                    font-weight: 500;

                    &__title--0 {
                        font-weight: 500;
                    }
                }

                &__time {
                    max-width: 5cm;
                    display:flex;
                    gap: 0.1cm;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    // flex-basis: 2.2cm;
                }
            }
        }
    }
}