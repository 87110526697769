@import './keyframes.scss';
@import './bootstrapoverrides.scss';

html {
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  
}
main {
  overflow:auto;
}

#root {
  min-height: 100vh;
  min-width: 100%;
  height: 100%;
}

.spinner {
  text-align: center;
  position: absolute;
  width: 100%;
  margin:20px auto;
  z-index:1000;
}
.spin {
  animation: spin 2s linear infinite;
}
.btn__spinner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:7px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label {
  font-weight: bold;
  font-size: .8rem;
}
label.form-label {
  margin-bottom:0;
  margin-top:1rem;
}

.activies {
  height: 100%;
  overflow: hidden;
  display:flex;
  flex-direction: column;
  
  & > div {
    overflow: hidden;
  }

  & > div > div {
    overflow-y: auto;
  }
}

.schedule {
  &__activities {
      max-height: 250px;
      min-height: 250px;
      overflow-y:scroll;
  }
  &__blockheader {
      background-color:#ddd;
  }
  &__blockcontent {
      font-size:.9rem;

      table {
          width:100%;
          .btn-sm {
              padding:.2rem 0.4rem;
              font-size: .7rem;
          }
          tr:first-child {
              td {
                  border-top:solid 1px #aaa;
              }
          }

          td {
              border-bottom:solid 1px #aaa;
          }
      }
  }

  &__cell-sm {
      width:50px;
  }
  &__cell-md {
      width:150px;
  }
}


.picturesPage {
  .singlePicture img {
    max-width: 100px;
    max-height: 100px;
  }
}