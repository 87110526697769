@media screen and (max-width: 786px) {
    #root {
        position: relative;
    }

    nav {
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        width:100% !important;
        z-index: 20;

        >ul {
            display: none !important;
            font-size: 1.25rem;
        }

        >.dropdown {
            display: none !important;
        }

        &.open {
            bottom: 0;


            >ul {
                display: block !important;
            }

            >.dropdown {
                display: block !important;
            }
        }
    }

    main {
        margin-top:70px;
    }
}